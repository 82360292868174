import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {fetchPlans, PlansState} from "../../../actions/plans";
import {selectPlan} from "../../../actions/checkout";
import {useNavigate, useLocation} from "react-router-dom";
import PlanCard from "./PlanCard";
import {StudioState} from "../../../actions/studio";
import {AuthState} from "../../../actions/auth";
import baseApi from "../../../apis/baseApi";
import LoadingModal from "./LoadingModal";
import {RegistrationFee} from "./RegistrationFees";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard} from "@fortawesome/free-solid-svg-icons";
import {getApiErrorMessage} from "../../../utils/api_utils";


const Plans = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const plansState = useSelector<StoreState, PlansState>(state => state.plans)
    const studioState = useSelector<StoreState, StudioState>(state => state.studio)
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [registrationFees, setRegistrationFees] = useState<RegistrationFee[]>([])
    const [loadingRegistrationFees, setLoadingRegistrationFees] = useState(false)
    const paramCurrency = useRef<string>();
    const [planError, setPlanError] = useState<string>();

    useEffect(() => {
        if (authState.submitting) {
            return
        }
        if (!plansState.fetched) {
            if (authState.isLoggedIn) {
                dispatch(fetchPlans())
            } else {
                const params = new URLSearchParams(location.search)
                const branch = params.get('branch') || undefined
                const studio = params.get('studio') || undefined
                paramCurrency.current = params.get('currency') || undefined
                if (branch && studio) {
                    dispatch(fetchPlans(branch, studio))
                    // setLoadingRegistrationFees(true)
                    // baseApi.get(`/registration-fees/?studio=${studio}`).then(resp => {
                    //     setRegistrationFees(resp.data.results)
                    // })
                }
                // else {
                //     navigate('/login')
                // }
            }
        }
    }, [dispatch, plansState, location, authState]);

    useEffect(() => {
        if (authState.isLoggedIn && studioState.studio.id && !loadingRegistrationFees && studioState.studio.preferences.payment_processor == "ST") {
            setLoadingRegistrationFees(true)
            baseApi.get(`/registration-fees/?studio=${studioState.studio.id}`).then(resp => {
                setRegistrationFees(resp.data.results)
            })
        }
    }, [authState, studioState]);

    if (plansState.loading) {
        return <div className='font-size-h5'>Cargando planes...</div>
    }
    if (plansState.error) {
       return <div className="badge badge-danger text-center mt-5 font-size-h4">
            {plansState.error}
        </div>
    }
    return (
        <div>
            <LoadingModal show={showLoadingModal} onHide={() => setShowLoadingModal(false)} errorMessage={planError}/>
            <div hidden={registrationFees.length === 0} className="mt-10">
                <Button size="sm" variant="outline-primary" onClick={() => {
                    navigate("/registration-fees", {state:{ registrationFees: registrationFees}})
                }}><FontAwesomeIcon icon={faIdCard}/> Ver Inscripciones</Button>
            </div>
            <div className="row mt-10">
                {plansState.plans.map(plan => {
                    return <div className="col-6 col-md-3" key={plan.id} onClick={() => {
                        dispatch(selectPlan(plan))
                        setPlanError(undefined)
                        if (!authState.isLoggedIn) {
                            const params = new URLSearchParams(location.search)
                            const studio = params.get('studio') || undefined
                            const branch = params.get('branch') || undefined
                            let path = '/login';
                            if (studio) {
                                path += `?studio=${studio}`
                            }
                            if (branch) {
                                path += `&branch=${branch}`
                            }
                            navigate(path)
                        } else if (studioState.studio.preferences.payment_processor === "ST") {
                            navigate("/checkout")
                        } else if (studioState.studio.preferences.payment_processor === "MP") {
                            navigate("/checkout-mp")
                        } else if (studioState.studio.preferences.payment_processor == "WM") {
                            setShowLoadingModal(true)
                            let url = `/wompi/link/?plan=${plan.id}`
                            if (plan.promotions.length > 0) {
                                const promotion = plan.promotions[0]
                                url += `&promotion=${promotion.id}`
                            }
                            baseApi.get(url).then(resp => {
                                setShowLoadingModal(false)
                                let url = resp.data.urlEnlace
                                window.open(url, "_self")
                            }).catch(err => setPlanError(getApiErrorMessage(err)))
                        } else {
                            alert("Lo sentimos, tu estudio aun no acepta pagos en linea.")
                        }

                    }}>
                        <PlanCard plan={plan} paramCurrency={paramCurrency.current}/>
                    </div>
                })}
            </div>
        </div>
    );
};

export default Plans;

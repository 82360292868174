import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {addCoupon, CheckoutState, createPaymentIntent} from "../../../actions/checkout";
import {useNavigate} from "react-router-dom";
import PlanCard from "../Plans/PlanCard";
import {getBrandIcon} from "../../../utils/card_utils";
import baseApi from "../../../apis/baseApi";
import {getApiErrorMessage} from "../../../utils/api_utils";
import {Field, Form, Formik} from "formik";
import {Coupon} from "../../../data/Coupon";
import FieldError from "../../misc/FieldError";
import {Button} from "react-bootstrap";
import PaymentMethods from "./PaymentMethods";

enum DisplayOptions {purchase, paymentMethodSelect}

const Checkout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const checkoutState = useSelector<StoreState, CheckoutState>(state => state.checkout)
    const [errorMsg, setErrorMsg] = useState<string>();
    const [checkoutSessionLoading, setCheckoutSessionLoading] = useState(false);
    const [componentDisplay, setComponentDisplay] = useState(DisplayOptions.purchase);

    useEffect(() => {
        if (checkoutState.success && !checkoutState.selectedPlan) {
            navigate("/purchase-details", {replace: true})
        }
    }, [checkoutState]);

    const renderFreePlanButton = () => {
        if (parseFloat(checkoutState.selectedPlan!.price) > 0) return <React.Fragment/>
        return <div className="text-center">
            <button
                className="btn btn-primary btn-lg  font-weight-bolder mt-5 px-20"
                onClick={() => {
                    setErrorMsg(undefined)
                    baseApi.post("/sales/freebuy/", {
                        plan: checkoutState.selectedPlan!.id
                    }).then(resp => {
                        console.log(resp.data)
                        navigate("/purchase-details")
                    }).catch(err => {
                        setErrorMsg(getApiErrorMessage(err))
                    })
                }}
            >
                Comprar
            </button>
        </div>
    }

    if (!checkoutState.selectedPlan) {
        navigate("/", {replace: true})
        return <React.Fragment/>
    }
    if (componentDisplay == DisplayOptions.paymentMethodSelect) {
        return <PaymentMethods onGoBack={() => setComponentDisplay(DisplayOptions.purchase)}/>
    }
    return (
        <div>
            <div
                className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 bg-white my-5 shadow-lg rounded-xl mx-auto pb-10">
                {/* BUTON AND CONFIRM TEXT */}
                <div className="d-flex text-center align-items-center mb-2 pt-3">
                    <button className="btn mr-auto" onClick={() => {
                        navigate(-1)
                    }}><FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    {/*<p className={"font-size-h6 align-self-center ml-2"}>Agregar método de pago</p>*/}
                    <div className="text-center font-size-h3 ml-n10  mr-auto">Confirma tu compra</div>
                </div>


                {/* ERROR */}
                <div hidden={checkoutState.error === undefined}
                     className="alert alert-danger">Error: {checkoutState.error}
                </div>


                {/* PLAN CARD */}
                <div className="row justify-content-center">
                    <div className="col-10 col-xl-10 col-xxl-8 ">
                        <PlanCard plan={checkoutState.selectedPlan!} checkoutMode={true}/>
                    </div>
                </div>


                <div className="row justify-content-center mt-n5">
                    <div className="" hidden={checkoutState.selectedPlan.is_subscription}>
                        <div className="">
                            <div hidden={parseFloat(checkoutState.selectedPlan.price) <= 0}
                                 className="font-size-h6 mt-4">Método de pago
                            </div>
                            <button hidden={parseFloat(checkoutState.selectedPlan.price) <= 0}
                                    onClick={() => {
                                        setComponentDisplay(DisplayOptions.paymentMethodSelect)
                                    }} className="btn btn-secondary btn-lg btn-block">
                                <div className="d-flex justify-content-between">
                                    {checkoutState.selectedPaymentMethod === undefined ?
                                        <div className="text-muted">Selecciona tu método de pago</div> :
                                        <div>
                                        <span className="text-dark-50 mr-2"><FontAwesomeIcon style={{fontSize: "16px"}}
                                                                                             icon={getBrandIcon(checkoutState.selectedPaymentMethod.card)}/></span>
                                            &middot;&middot;&middot;&middot;{checkoutState.selectedPaymentMethod.card.last4}
                                        </div>}
                                    <i className="text-muted"><FontAwesomeIcon icon={faAngleRight}/> </i>
                                </div>
                            </button>
                            <div className="text-center text-danger">
                                {errorMsg}
                            </div>
                        </div>
                        {/* CUPON */}
                        <div className="mt-2">
                            <Formik initialValues={{coupon_code: checkoutState?.coupon?.redeem_code}}
                                    onSubmit={(values, {setSubmitting, setFieldError}) => {
                                        baseApi.post<Coupon>("/coupons/validate/", {
                                            ...values,
                                            plan: checkoutState.selectedPlan!.id,
                                        }).then(resp => {
                                            dispatch(addCoupon(resp.data))
                                        }).catch(err => {
                                            setFieldError("coupon_code", getApiErrorMessage(err))
                                            setSubmitting(false)
                                        })
                                    }}>
                                {({values, isSubmitting}) => (
                                    <Form>
                                        <div className="d-flex px-6">
                                            <div className="w-75">
                                                <Field name="coupon_code"
                                                       className="form-control form-control-"
                                                       placeholder="Código de descuento"/>
                                                <FieldError name="coupon_code"/>
                                            </div>
                                            <div hidden={checkoutState.coupon !== undefined} className="">
                                                <Button className='font-size-sm text-success'
                                                        type="submit"
                                                        disabled={isSubmitting} variant="outline-secondary"
                                                >
                                                    {/* <FontAwesomeIcon icon={faCheck}/> */}
                                                    Aplicar
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <div hidden={!checkoutState.coupon}
                                 className="text-success text-center">{checkoutState.coupon?.title} (-{checkoutState.coupon?.discount_amount}{checkoutState.coupon?.is_percent ? '%' : ''})
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5">
                        <div className="text-center" hidden={!checkoutState.selectedPlan.is_subscription}>
                            Este plan se renueva automáticamente.
                        </div>
                        <div className="">
                            {renderFreePlanButton()}
                            <button
                                disabled={checkoutSessionLoading}
                                hidden={!checkoutState.selectedPlan.is_subscription}
                                className="btn btn-primary btn-lg btn-block font-weight-bolder mt-5"
                                onClick={() => {
                                    setCheckoutSessionLoading(true)
                                    let url = window.location.origin
                                    console.log(url)
                                    let planId = checkoutState.selectedPlan!.id
                                    baseApi.post("/stripe/checkout-session/", {plan: planId, url: url}).then(resp => {
                                        window.open(resp.data.url, '_blank')
                                    }).catch(err => {
                                        setErrorMsg("Ocurrió un error")
                                    })
                                }}>
                                Suscribirme
                            </button>

                            {/* confirmar compra */}
                            <button
                                hidden={parseFloat(checkoutState.selectedPlan.price) <= 0 || checkoutState.selectedPlan.is_subscription}
                                disabled={checkoutState.selectedPaymentMethod === undefined || checkoutState.submitting}
                                className="btn btn-primary btn-lg btn-block font-weight-bolder mt-5 "
                                onClick={() => {
                                    if (checkoutState.selectedPaymentMethod) {
                                        let pmId = checkoutState.selectedPaymentMethod.id
                                        let planId = checkoutState.selectedPlan!.id
                                        let promotion = checkoutState.selectedPlan!.promotions.length === 0 ? undefined : checkoutState.selectedPlan?.promotions[0].id
                                        dispatch(createPaymentIntent(pmId, planId, promotion, checkoutState.coupon?.redeem_code))
                                    }
                                }}
                            >
                                Confirmar compra
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
